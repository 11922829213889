export const parseAnalyticsUser = (userData) => {
  return userData ? {
    full_name: userData.full_name,
    name: userData.name,
    surname: userData.surname,
    uuid: userData.id?.toString?.(),
    gdpr_optin: userData.terms,
    // email_optin: userData.notification_email,
    // sms_optin: userData.notification_sms,
    email: userData.email,
    phone_number: userData.mobile_number_formatted,
  } : null;
}

export default parseAnalyticsUser;